<template>
  <div class="container-fluid" id="headline">
    <div class="row row-cols-1 justify-content-lg-center">
      <div class="col">
        <h2><i :class="image"></i>{{ title }}</h2>
      </div>
      <div :class="[description ? 'col' : 'col d-none']">
        <p>{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadlineView",
  props: ["title", "description", "image"],
};
</script>

<style scoped>
#headline .row {
  margin-top: 15px;
  margin-bottom: 30px;
}

#headline .row {
  text-align: left;
}
</style>

import { createWebHashHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import PhotoOfTheWeek from "@/components/PhotoOfTheWeek.vue";
import Project from "@/components/Project.vue";
import Projects from "@/components/Projects.vue";
import Contact from "@/components/Contact.vue";

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: Home,
  },
  {
    path: "/photoOfTheWeek",
    component: PhotoOfTheWeek,
  },
  {
    path: "/projects",
    component: Projects,
  },
  {
    path: "/projects/:id",
    component: Project,
  },
  {
    path: "/contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

export default router;

<template>
  <Navigation />
  <router-view />
  <SiteFooter />
</template>

<script>
import Navigation from "./components/Navigation.vue";
import SiteFooter from "./components/SiteFooter.vue";

export default {
  name: "App",
  components: {
    SiteFooter,
    Navigation,
  },
};
</script>

<style></style>

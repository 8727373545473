<template>
  <div class="container-fluid" id="project">
    <div class="row row-cols-1 row-cols-sm-2">
      <div class="col col-sm-12 col-md-4 col-lg-4 col-xl-3" id="description">
        <h2 class="title"><i class="bi bi-image" />{{ project.name }}</h2>
        <p>{{ project.time }}</p>
        <p>{{ project.description }}</p>
      </div>
      <div class="col col-sm-12 col-md-8 col-lg-8 col-xl-9" id="images">
        <!-- XS / 1 COLUMN -->
        <div class="container-fluid d-xs-block d-sm-none">
          <div class="row row-cols-xs-1">
            <div class="col">
              <img
                v-for="image in project.images"
                v-bind:key="image.id"
                v-on:click="lightbox.loadAndOpen(image.index)"
                :src="image.thumbnail"
                class="w-100"
              />
            </div>
          </div>
        </div>
        <!-- SM + MD / 2 COLUMNS -->
        <div class="container-fluid d-none d-sm-block d-md-block d-lg-none">
          <div class="row row-cols-sm-2 row-cols-md-2 gx-2">
            <div
              class="col"
              v-for="col in getColumnItemsMap(2)"
              v-bind:key="col.id"
            >
              <img
                v-for="image in col"
                v-bind:key="image.id"
                v-on:click="lightbox.loadAndOpen(image.index)"
                :src="image.thumbnail"
                class="w-100"
              />
            </div>
          </div>
        </div>
        <!-- LG + XL / 3 COLUMNS -->
        <div class="container-fluid d-none d-lg-block d-xl-block">
          <div class="row row-cols-lg-3 row-cols-xl-3 gx-2">
            <div
              class="col"
              v-for="col in getColumnItemsMap(3)"
              v-bind:key="col.id"
            >
              <img
                v-for="image in col"
                v-bind:key="image.id"
                v-on:click="lightbox.loadAndOpen(image.index)"
                :src="image.thumbnail"
                class="w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsData from "../assets/data/projects.json";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeFullscreen from "../mixins/photoswipe-fullscreen.esm.min.js";
import "photoswipe/style.css";

export default {
  name: "ProjectView",
  components: {},
  data() {
    return {
      project: this.getProject(),
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        showHideAnimationType: "none",
        pswpModule: () => import("photoswipe"),
      });

      this.lightbox.addFilter("numItems", () => {
        return this.getProject().images.length;
      });

      this.lightbox.addFilter("itemData", (data, index) => {
        var item = this.getProject().images[index];
        return {
          src: item.src,
          width: item.w,
          height: item.h,
        };
      });

      this.lightbox.on('afterInit', () => {
        this.fullscreenPlugin.toggleFullscreen();
      });

      this.fullscreenPlugin = new PhotoSwipeFullscreen(this.lightbox);

      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {
    getProject() {
      return ProjectsData.filter(
        (project) => project.id === this.$route.params.id
      )[0];
    },
    getColumnItemsMap(numColumns) {
      var sumHeight = this.getProject()
        .images.map((item) => item.h)
        .reduce((sum, current) => sum + current, 0);
      var heightPerColumn = sumHeight / numColumns;

      var result = {};
      var currentColumnIndex = 1;
      var currentColumnHeight = 0;
      this.getProject().images.forEach((element, elementIndex) => {
        if (!result[currentColumnIndex]) {
          result[currentColumnIndex] = new Array();
        }
        element.index = elementIndex;
        result[currentColumnIndex].push(element);
        currentColumnHeight += element.h;
        if (
          currentColumnHeight > heightPerColumn &&
          currentColumnIndex < numColumns
        ) {
          currentColumnIndex += 1;
          currentColumnHeight = 0;
        }
      });

      return result;
    },
  },
};
</script>

<style scoped>
#project {
  padding-top: 15px;
}

#project p {
  padding-right: 15px;
}

#images .container-fluid {
  padding: 10px 0 0 0;
}

#images img {
  cursor: pointer;
  padding-bottom: 8px;
}

#images img:hover {
  opacity: 0.6;
}
</style>

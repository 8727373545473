<template>
  <Headline
    title="Projekte"
    description="Übersicht über meine Projekte aus den vergangenen Jahren"
    image="bi-images"
  ></Headline>
  <div class="container-fluid" id="projects">
    <div
      class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 gx-3 gy-2"
    >
      <div class="col" v-for="project in projects" v-bind:key="project.id">
        <router-link :to="'/projects/' + project.id">
          <div class="card" :to="'/projects/' + project.id">
            <img :src="`${project.thumbnail}`" class="card-img-top" />
            <div class="card-body">
              <h5 class="card-text">
                {{ project.name }}
              </h5>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Headline from "./Headline.vue";
import ProjectsData from "../assets/data/projects.json";
export default {
  name: "ProjectsView",
  components: {
    Headline,
  },
  data() {
    return {
      projects: ProjectsData,
    };
  },
};
</script>

<style scoped>
#projects .card {
  border: 0px;
  border-radius: 0;
}

#projects .card:hover .card-img-top {
  opacity: 0.6;
}

#projects .card .card-body {
  padding: 10px;
}

#projects .card .card-img-top {
  border-radius: 0px;
}

#projects .card .card-text {
  text-align: center;
}
</style>

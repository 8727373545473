<template>
  <div class="container" id="home">
    <img src="/images/20.jpg" class="w-100"/>
    <p class="subtitle">
      Webseite von L.Kunze 
    </p>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
#home {
  padding: 25px 10% 20px 10%;
}

.subtitle {
  padding: 10px;
  text-align: center;
}
</style>

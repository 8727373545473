<template>
  <Headline
    title="Kontakt"
    description="Wenn du Kontakt mit mir aufnehmen möchtest, kannst du mir eine Email an | info [at] lkunze.de | schreiben."
    image="bi-envelope-at"
  ></Headline>
</template>

<script>
import Headline from "./Headline.vue";
export default {
  name: "ContactView",
  components: {
    Headline,
  },
};
</script>

<style scoped></style>

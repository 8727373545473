<template>
  <footer id="footer">
    <div><span>&copy; lkunze.de 2024</span></div>
  </footer>
</template>

<script>
export default {
  name: "FooterView",
};
</script>

<style scoped>
#footer {
  margin: 0 12px 0 12px;
}

#footer div {
  margin-top: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  font-family: "barlow_condensed_light";
  font-size: 20px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
</style>

<template>
  <div id="navigation">
    <nav class="navbar position-sticky sticky-top navbar-expand-lg">
      <div class="container-fluid">
        <RouterLink to="/" class="navbar-brand" v-on:click="hideMenu()"
          >lkunze</RouterLink
        >
        <button
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse multi-collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
          ref="navbarSupportedContent"
        >
          <ul class="navbar-nav">
            <li class="nav-item" v-on:click="hideMenu()">
              <RouterLink to="/photoOfTheWeek" class="nav-link">
                Foto der Woche
              </RouterLink>
            </li>
            <li class="nav-item" v-on:click="hideMenu()">
              <RouterLink
                to="/projects"
                :class="[
                  $route.fullPath.startsWith('/projects')
                    ? 'router-link-exact-active'
                    : '',
                  'nav-link',
                ]"
              >
                Projekte
              </RouterLink>
            </li>
            <li class="nav-item" v-on:click="hideMenu()">
              <RouterLink to="/contact" class="nav-link">Kontakt</RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import ProjectsData from "../assets/data/projects.json";
export default {
  name: "NavigationView",
  components: {},
  data() {
    return {
      projects: ProjectsData,
    };
  },
  methods: {
    hideMenu() {
      document
        .getElementById("navbarSupportedContent")
        .classList.remove("show");
    },
  }
};
</script>

<style scoped>
#navigation {
  font-family: "barlow_condensed_thin";
}
#navigation .navbar {
  padding-bottom: 0px;
}

#navigation .navbar-brand {
  margin-left: -2px;
  padding-left: 0px;
  font-size: 40pt;
  font-weight: normal !important;
  font-family: "barlow_condensed_light" !important;
  color: rgba(0, 0, 0, 0.8);
}

#navigation .nav-item {
  padding-left: 20px;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.8);
}

#navigation .nav-item {
  padding-left: 20px;
  font-size: 1.5rem;
}

#navigation .nav-link a {
  color: rgba(0, 0, 0, 0.8) !important;
}

#navigation button {
  border: none;
}

#navigation ul {
  border: none;
  font-size: 1.5rem;
}

#navigation .nav-item:last-of-type .nav-link {
  padding-right: 0px;
}

#navigation a {
  padding-bottom: 0px;
}

#navigation a.router-link-active {
  font-weight: 500;
  font-family: "barlow_condensed_regular";
  color: rgba(0, 0, 0, 0.8);
}

#navigation a.router-link-exact-active {
  font-weight: normal;
  font-family: "barlow_condensed_regular";
  color: rgba(0, 0, 0, 0.8);
}

#navigation ul.dropdown-menu a.router-link-active {
  font-weight: normal;
  font-family: "barlow_condensed_regular";
  color: rgba(0, 0, 0, 0.8);
}

#navigation ul.dropdown-menu a.router-link-exact-active {
  font-weight: normal;
  font-family: "barlow_condensed_regular";
  color: rgba(0, 0, 0, 0.8);
}

.show {
  margin-bottom: 20px;
}
</style>

<template>
  <Headline
    title="Foto der Woche"
    description="Ich versuche jede Woche ein Foto zu machen. Sollte mir das gelingen, veröffentliche ich es hier."
    image="bi-image"
  ></Headline>
  <div class="container-fluid" id="photoOfTheWeek">
    <div
      id="carouselExampleCaptions"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div
          :class="[index == 0 ? 'active' : '', 'carousel-item']"
          v-for="(photo, index) in photos"
          v-on:click="lightbox.loadAndOpen(index)"
          v-bind:key="photo.id"
        >
          <img :src="photo.src" class="d-block w-100" />
          <div class="carousel-caption">
            <span class="caption">{{ photo.weekOfYear }}</span>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import Headline from "./Headline.vue";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeFullscreen from "../mixins/photoswipe-fullscreen.esm.min.js";
import "photoswipe/style.css";
import PhotoOfTheWeekData from "../assets/data/photoOfTheWeek.json";

export default {
  name: "PhotoOfTheWeekView",
  components: {
    Headline,
  },
  data() {
    return {
      photos: PhotoOfTheWeekData,
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        pswpModule: () => import("photoswipe"),
      });

      this.lightbox.addFilter("numItems", () => {
        return PhotoOfTheWeekData.length;
      });

      this.lightbox.addFilter("itemData", (data, index) => {
        var item = PhotoOfTheWeekData[index];
        return {
          src: item.src,
          width: item.w,
          height: item.h,
          index: index,
        };
      });

      this.fullscreenPlugin = new PhotoSwipeFullscreen(this.lightbox);

      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
};
</script>

<style scoped>
#photoOfTheWeek img {
  cursor: pointer;
}

#photoOfTheWeek .caption {
  color: white !important;
  font-family: "barlow_condensed_regular";
  font-size: 1.4rem;
}
</style>
